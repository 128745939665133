import React, { useEffect } from 'react';
import style from '../styles/Contact.module.scss';

function Contact() {
  useEffect(() => {
    document.title = 'Contact - Corvettes of Lodi';
  }, []);

  return (
    <div className={style.contactInfo}>
      <section className={style.contactPageMeetingsSection}>
        <h2>Meetings</h2>
        <div className={style.contactPageMeetingsSectionMainDiv}>
          <div>
            <p>Meetings are on the <span className={style.contactBold}>first Thursday of every month </span>
              at <span className={style.contactBold}>Octavio's</span> in the Marina Center:
            </p>
            <p>3201 W. Benjamin Holt Drive, Stockton CA, 95219</p>
            <p>Dinner Time: 5:00 PM - 6:00 PM</p>
            <p>Meeting Time: 6:30 PM</p>
            <p>
              Note: Members pay for their own meals and are encouraged to eat at the restaurant
            </p>
          </div>
          <iframe
            className={style.restaurantIFrame}
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.750263016652!2d-121.35903131102633!3d38.0062852264201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80900ea7ec9625b5%3A0xfa18063155e660a3!2sOctavio&#39;s!5e0!3m2!1sen!2sus!4v1704417107241!5m2!1sen!2sus'
            width='200'
            height='200'
            style={{ border: 0 }}
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
            title='Google Map for Sugar Mediterranean Bistro'
          ></iframe>
        </div>
      </section>
      <section className={style.contactPageContactSection}>
        <h2>Contact Info</h2>
        <div>
          <p>Club Event Contact: Stan Krafft</p>
          <p>Email: stanandelle@gmail.com</p>
        </div>
        <div>
          <p>Club Membership Contact: Jennifer Krafft</p>
          <p>Email: sjhsjhk@gmail.com</p>
        </div>
        <div>
          <p>Club Mailbox:</p>
          <p>P.O. Box 811, Lodi CA 95241</p>
          <p>Club Email: corvettesoflodi@gmail.com</p>
        </div>
      </section>
    </div>
  );
}

export default Contact;
