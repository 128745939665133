import React from 'react';
import { Link } from 'react-router-dom';
import style from '../styles/Page404.module.scss';
import Image404 from '../assets/scenic-route.jpg';
function Page404() {
  return (
    <div className={style.Page404MainDiv}>
      <h1>404</h1>
      <h2>PAGE NOT FOUND</h2>
      <p>Although we love to take the scenic route, let's get back on track.</p>
      <img className={style.Page404Image} src={Image404} alt='404 Error - Scenic Route'/>
      <Link to='/'><button className={style.Page404HomeButton}>Home</button></Link>
    </div>
  )
}

export default Page404;