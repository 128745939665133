import React, { useEffect } from 'react';
import style from '../styles/Membership.module.scss';
import membersPhoto from '../assets/membership.jpg';

function Membership() {
  useEffect(() => {
    document.title = 'Membership - Corvettes of Lodi';
  }, []);

  return (
    <div className={style.container}>
      <h1>Corvettes of Lodi Membership</h1>
      <img className={style.headerImg} src={membersPhoto} alt='corvettes of lodi members' />
      <p>
        Please fill out the appropriate form completely and return it to the membership director
        (Jennifer Krafft) or mail to P.O. Box 811, Lodi, CA 95241 with your check payable to:{' '}
        <u>Corvettes of Lodi</u>
      </p>
      <section>
        <h2>Membership Renewal</h2>
        <ul className={style.membershipInfo}>
          <li>
            Membership renewal dues are due on <b>November 1st</b>.
          </li>
          <li>If dues are not paid by November 30th there will be a $20.00 late charge.</li>
          <li>Annual dues are $50.00 per person.</li>
          <li>
            An active member who does not pay within sixty (60) days of the billing date will be
            automatically dropped from the membership.
          </li>
        </ul>
        <a
          className={style.applicationLink}
          href='https://corvettesoflodi.com/wp-content/uploads/2023/01/COL-Renewal.pdf'
          target='_blank'
          rel='noreferrer'
        >
          Membership Renewal Form
        </a>
      </section>
      <section>
        <h2>Membership Application</h2>
        <ul className={style.membershipInfo}>
          <li>
            New member fees are $50.00 per person. Annual dues shall be prorated quarterly for
            partial year membership. Example: Jan-Mar $50, Apr-Jun $37.50, Jul-Sept $25, Oct-Nov
            $12.50
          </li>
          <li>
            Name Badge(s) order is due with new membership application @ $20.00 as a one time
            expense. Badges are laser engraved with your first and last name along with the
            year, color, and model of your Corvette.
          </li>
        </ul>
        <a
          className={style.applicationLink}
          href='https://corvettesoflodi.com/wp-content/uploads/2023/01/COL-Application.pdf'
          target='_blank'
          rel='noreferrer'
        >
          Membership Application Form
        </a>
      </section>
      <section>
        <h2>Club Bylaws</h2>
        <ul className={style.membershipInfo}>
          <li>
            Bylaws are rules set by our club so that it can be self regulating. We do our best
            to create an environment where everyone can relax, feel welcome, and enjoy
            themselves. Bylaws also ensure that certain standards and practices are met and
            maintained regardless of who is in charge. They can be thought of as internal laws
            for our club.
          </li>
          <li>Any members that wish to join our club must agree to adhere to these laws.</li>
          <a
            className={style.applicationLink}
            href='https://www.corvettesoflodi.com/wp-content/uploads/2024/09/COL-BYLAWS_Feb2023_PJO-edits.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Club Bylaws
          </a>
        </ul>
      </section>
    </div>
  );
}

export default Membership;
