import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Contact from './pages/Contact';
import CorvetteFest from './pages/CorvetteFest';
import Events from './pages/Events';
import Footer from './components/Footer';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import Membership from './pages/Membership';
import Navbar from './components/Navbar';
import Newsletters from './pages/Newsletters';
import Page404 from './pages/Page404';
import './App.css';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/membership' element={<Membership />} />
          <Route path='/newsletters' element={<Newsletters />} />
          <Route path='/events' element={<Events />} />
          <Route path='/corvette-fest-car-show' element={<CorvetteFest />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/gallery/page/:pageNum' element={<Gallery />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
