import React from 'react';
import style from '../styles/Pagination.module.scss';
import Icon from './Icon';

const SIBLING_COUNT = 2; // min number of page numbers to show on left and right of current page link

function Pagination({ currPage, totalPages, onChange }) {
  if (!totalPages) {
    return null;
  }

  let pageNumbers = [];
  const numDisplayedPages = SIBLING_COUNT + 5;
  if (numDisplayedPages >= totalPages) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const leftSiblingIndex = Math.max(currPage - SIBLING_COUNT, 1); // index of LEFTMOST sibling
    const rightSiblingIndex = Math.min(currPage + SIBLING_COUNT, totalPages); // index of RIGHTMOST sibling

    const showLeftFiller = leftSiblingIndex > 2;
    const showRightFiller = rightSiblingIndex < totalPages - 2;

    if (!showLeftFiller && showRightFiller) {
      const leftItemCount = 3 + 2 * SIBLING_COUNT;
      let leftPageNumbers = [];
      for (let i = 1; i <= leftItemCount; i++) {
        leftPageNumbers.push(i);
      }
      pageNumbers = [...leftPageNumbers, 'R', totalPages];
    } else if (showLeftFiller && !showRightFiller) {
      let rightItemCount = 3 + 2 * SIBLING_COUNT;
      let rightPageNumbers = [];
      for (let i = totalPages - rightItemCount + 1; i <= totalPages; i++) {
        rightPageNumbers.push(i);
      }
      pageNumbers = [1, 'L', ...rightPageNumbers];
    } else {
      let middlePageNumbers = [];
      for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
        middlePageNumbers.push(i);
      }
      pageNumbers = [1, 'L', ...middlePageNumbers, 'R', totalPages];
    }
  }

  return (
    <div className={style.container}>
      <ul className={style.pageNumbers}>
        <li>
          <button
            className={currPage === 1 ? style.disabled : null}
            onClick={() => {
              if (currPage > 1) {
                onChange(currPage - 1);
              }
            }}
          >
            <Icon name='chevronLeft' width='20' height='20' />
          </button>
        </li>
        {pageNumbers.map((pageNum) => {
          return (
            <li key={pageNum}>
              {isNaN(pageNum) ? (
                <span>...</span>
              ) : (
                <button
                  className={pageNum === currPage ? style.active : null}
                  onClick={() => onChange(pageNum)}
                >
                  {pageNum}
                </button>
              )}
            </li>
          );
        })}
        <li>
          <button
            className={currPage === totalPages ? style.disabled : null}
            onClick={() => {
              if (currPage < totalPages) {
                onChange(currPage + 1);
              }
            }}
          >
            <Icon name='chevronRight' width='20' height='20' />
          </button>
        </li>
      </ul>
    </div>
  );
}

//https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
export default Pagination;
