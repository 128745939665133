import React from 'react';
import { useEffect, useState } from 'react';
import style from '../styles/Events.module.scss';

function Events() {
  const [screenWidth, setScreenWidth] = useState([window.innerWidth]);

  useEffect(() => {
    document.title = 'Upcoming Events - Corvettes of Lodi';
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth([window.innerWidth]);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function calendarChanger() {
    if (screenWidth < 400) {
      return (
        <iframe
          className={style.googleEventTable}
          src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&showCalendars=0&showPrint=0&showDate=1&showTabs=1&mode=AGENDA&src=Y29ydmV0dGVzb2Zsb2Rpd2Vic2l0ZUBnbWFpbC5jb20&color=%23D50000'
          width='800'
          height='600'
          style={{ border: 'solid 1px #777' }}
          frameBorder='0'
          scrolling='no'
          title='Club calendar'
        ></iframe>
      );
    } else {
      return (
        <iframe
          title='club calendar'
          className={style.googleEventTable}
          src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=1&title=Corvettes%20of%20Lodi%20Events&showCalendars=0&showPrint=0&src=Y29ydmV0dGVzb2Zsb2Rpd2Vic2l0ZUBnbWFpbC5jb20&color=%23D50000'
          width='800'
          height='600'
          style={{ border: 'solid 1px #777' }}
          frameBorder='0'
          scrolling='no'
        ></iframe>
      );
    }
  }

  return (
    <div className={style.container}>
      <h1>Upcoming Events</h1>
      {calendarChanger()}
    </div>
  );
}

export default Events;
