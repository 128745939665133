import React from 'react';
import { Link } from 'react-router-dom';
import style from '../styles/HeroSection.module.scss';

function HeroSection() {
  return (
    <div className={style.heroSection}>
      <div className={style.heroSectionOverlay}></div>
      <div className={style.heroSectionText}>
        <h1 className={style.heroSectionHeaderText}>United We Drive</h1>
        <p className={style.heroSectionSubText}>
          Create lasting friendships with Corvette enthusiasts
        </p>
        <Link className={style.newMemberButton} to='membership'>
          <button type='button'>Join Today</button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
