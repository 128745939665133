import React, { useEffect } from 'react';
import ClubFeatures from '../components/ClubFeaturesSection';
import NewMemberInfoSection from '../components/NewMemberInfoSection';
import HeroSection from '../components/HeroSection.jsx';
import clubGroupPhoto from '../assets/corvette-club-group-photo.png';
import style from '../styles/Home.module.scss';
import banner from '../assets/official-banner-offwhite.jpg';
import ClubOverview from '../components/ClubOverview';
import americanMuscle from '../assets/sponsor-logo-american-muscle.png';
import octavios from '../assets/octavios-main-logo.png';
import abel from '../assets/abel-chevrolet-logo.png';
import ecklers from '../assets/sponsor-logo-ecklers.png';
import idolBeers from '../assets/idol-beer-works-logo-transparency.png';

function Home() {
  useEffect(() => {
    document.title = 'Corvettes of Lodi';
  }, []);

  return (
    <div className={style.container}>
      <HeroSection />
      <div className={style.header}>
        <div className={style.banner}>
          <img src={banner} alt='Corvettes of Lodi banner' />
        </div>
      </div>
      <div className={style.mainSection}>
        <img className={style.corvettesImg} src={clubGroupPhoto} alt='Corvette cars' />
        <ClubOverview />
        <ClubFeatures />
      </div>
      <NewMemberInfoSection />
      <section className={style.sponsorsSection}>
        <h2>Proudly Sponsored By</h2>
        <div className={style.sponsorLogos}>
          <a href='https://www.abelgm.com/' target='_blank' rel='noreferrer'>
            <img src={abel} alt='Abel Chevrolet' />
          </a>
          <a
            href='https://www.americanmuscle.com/chevy-corvette-accessories-parts.html'
            target='_blank'
            rel='noreferrer'
          >
            <img src={americanMuscle} alt='American Muscle' />
          </a>
          <a
            href='https://www.ecklers.com/corvette-parts-accessories.html'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ecklers} alt="Eckler's" />
          </a>
          <a href='https://www.idolbeerworks.com/' target='_blank' rel='noreferrer'>
            <img src={idolBeers} alt='Idol Beer Works' />
          </a>
          <a href='https://www.octaviosrestaurant.com/' target='_blank' rel='noreferrer'>
            <img src={octavios} alt="Octavio's Restaurant" />
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
