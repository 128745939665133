import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useHideBodyOverflowY from '../hooks/useHideBodyOverflowY';
import useClickOutside from '../hooks/useClickOutside';
import Icon from './Icon';
import style from '../styles/Navbar.module.scss';

function Navbar() {
  const [showMenuItems, setShowMenuItems] = useState(false);
  const [showEventsSubLinks, setShowEventsSubLinks] = useState(false);
  const [showSponsorsSubLinks, setShowSponsorsSubLinks] = useState(false);
  const eventsMenuRef = useRef();
  const sponsorsMenuRef = useRef();

  useEffect(() => {
    const handleWindowResize = () => {
      // 1024 is the width at which full (non-hamburger) version of navbar shows
      if (window.innerWidth >= 1024) {
        setShowMenuItems(false);
        setShowEventsSubLinks(false);
        setShowSponsorsSubLinks(false);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useHideBodyOverflowY(showMenuItems);

  useClickOutside(eventsMenuRef, () => {
    // Don't close dropdown menus if viewing from hamburger menu navbar
    // Doing so would cause a jump when opening other dropdown menus.
    if (window.innerWidth >= 1024) {
      setShowEventsSubLinks(false);
    }
  });

  useClickOutside(sponsorsMenuRef, () => {
    if (window.innerWidth >= 1024) {
      setShowSponsorsSubLinks(false);
    }
  });

  const closeAllMenus = () => {
    setShowMenuItems(false);
    setShowEventsSubLinks(false);
    setShowSponsorsSubLinks(false);
  };

  return (
    <nav className={style.container}>
      <button
        className={style.menuToggle}
        onClick={() => {
          window.scrollTo(0, 0);
          setShowMenuItems(!showMenuItems);
        }}
      >
        {showMenuItems ? <Icon name='close' /> : <Icon name='hamburger' />}
      </button>

      <ul className={`${style.navLinksContainer} ${showMenuItems ? style.open : ''}`}>
        <li>
          <Link to='/' onClick={() => setShowMenuItems(false)}>
            Home
          </Link>
        </li>

        <li className={style.dropdownMenu} ref={eventsMenuRef}>
          <button
            className={showEventsSubLinks ? style.active : null}
            onClick={() => {
              setShowEventsSubLinks(!showEventsSubLinks);
            }}
          >
            Events
            <Icon name='chevronDown' width='20' height='20' />
          </button>
          {!showEventsSubLinks ? null : (
            <ul className={style.dropdownLinks}>
              <li>
                <Link
                  to='events'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Event Calendar
                </Link>
              </li>
              <li>
                <Link
                  to='corvette-fest-car-show'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Corvette Fest 2024 Registration
                </Link>
              </li>
            </ul>
          )}
        </li>

        <li>
          <Link to='gallery' onClick={() => closeAllMenus()}>
            Gallery
          </Link>
        </li>

        <li>
          <Link to='membership' onClick={() => closeAllMenus()}>
            Membership
          </Link>
        </li>

        <li>
          <Link to='newsletters' onClick={() => closeAllMenus()}>
            Newsletters
          </Link>
        </li>

        <li className={style.dropdownMenu} ref={sponsorsMenuRef}>
          <button
            className={showSponsorsSubLinks ? style.active : null}
            onClick={() => {
              setShowSponsorsSubLinks(!showSponsorsSubLinks);
            }}
          >
            Sponsors
            <Icon name='chevronDown' width='20' height='20' />
          </button>
          {!showSponsorsSubLinks ? null : (
            <ul className={style.dropdownLinks}>
              <li>
                <a
                  href='https://www.abelgm.com/'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Abel Chevrolet
                </a>
              </li>
              <li>
                <a
                  href='https://www.americanmuscle.com/chevy-corvette-accessories-parts.html'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  American Muscle
                </a>
              </li>
              <li>
                <a
                  href='https://www.ecklers.com/corvette-parts-accessories.html'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Eckler's
                </a>
              </li>
              <li>
                <a
                  href='https://www.idolbeerworks.com/'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Idol Beer Works
                </a>
              </li>
              <li>
                <a
                  href='https://www.octaviosrestaurant.com/'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    closeAllMenus();
                  }}
                >
                  Octavio's
                </a>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to='contact' onClick={() => closeAllMenus()}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
