import React from 'react';
import clubEventPhoto from '../assets/corvette-club-beer-event.png';
import clubRoadTrip from '../assets/corvette-club-road-trip-a.png';
import clubMembers from '../assets/corvette-club-members-photo.png';
import style from '../styles/ClubFeatures.module.scss';

function ClubFeaturesSection() {
  return (
    <section className={style.clubFeatureSection}>
      <div className={style.clubFeature}>
        <div>
          <p className={style.clubFeatureHeader}>Over 40 Years of Activity</p>
          <img className={style.clubFeatureImg} src={clubMembers} alt='club members' />
        </div>
        <p className={style.clubFeatureText}>
          Corvettes of Lodi has been around for over 40 years and after all these years it has
          continued to grow and allow members a place to share their interests and passion for
          Corvettes.
        </p>
      </div>
      <div className={style.clubFeatureBreak}>&nbsp;</div>
      <div className={style.clubFeature}>
        <div>
          <p className={style.clubFeatureHeader}>Road Trips</p>
          <img className={style.clubFeatureImg} src={clubRoadTrip} alt='club road trip' />
        </div>
        <p className={style.clubFeatureText}>
          Our cars aren't just for show. We love to take our Corvettes on road trips to various
          towns and cities, travelling well over an hour away. We travel to see new views and
          exciting places to eat. We always host a luncheon so we can relax with our fellow club
          members.
        </p>
      </div>
      <div className={style.clubFeatureBreak}>&nbsp;</div>
      <div className={style.clubFeature}>
        <div>
          <p className={style.clubFeatureHeader}>Car Shows</p>
          <img className={style.clubFeatureImg} src={clubEventPhoto} alt='club events' />
        </div>

        <p className={style.clubFeatureText}>
          Our members own Corvettes of various makes and models. We love to participate in local
          car shows. Car shows allow us to share our love of Corvettes to others and help to
          inform any interested people in the joys of owning a Corvette.
        </p>
      </div>
    </section>
  );
}

export default ClubFeaturesSection;
