import React from 'react';
import useHideBodyOverflowY from '../hooks/useHideBodyOverflowY';
import style from '../styles/Modal.module.scss';

import Icon from './Icon';
function Modal({ isDisplayed, onClose, children }) {
  useHideBodyOverflowY(isDisplayed);

  return (
    <>
      {isDisplayed ? (
        <div className={style.container}>
          <button className={style.closeButton} onClick={onClose}>
            <Icon name='close' />
          </button>
          <div className={style.content}>{children}</div>
        </div>
      ) : null}
    </>
  );
}

export default Modal;
