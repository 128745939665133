import React from 'react';
import styles from '../styles/NewMemberInfoSection.module.scss';
import { Link } from 'react-router-dom';

function NewMemberInfoSection() {
  return (
    <section className={styles.newMemberSection}>
      <div className={styles.backgroundContainer}></div>
      <p>Interested in joining?</p>
      <p>Click here for more info about joining the club!</p>
      <Link className={styles.newMemberButton} to='membership'>
        Membership Information
      </Link>
    </section>
  );
}

export default NewMemberInfoSection;
