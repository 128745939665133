import React from 'react';
import style from '../styles/Footer.module.scss';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-car.png';

function Footer() {
  return (
    <footer className={style.container}>
      <nav className={style.nav}>
        <div className={style.navSection}>
          <div className={style.linksHeader}>Club</div>
          <ul className={style.linksContainer}>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/events'>Events</Link>
            </li>
            <li>
              <Link to='/membership'>Membership</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </div>
        <div className={style.navSection}>
          <div className={style.linksHeader}>Explore</div>
          <ul className={style.linksContainer}>
            <li>
              <Link to='/gallery'>Gallery</Link>
            </li>
            <li>
              <Link to='/newsletters'>Newsletters</Link>
            </li>
          </ul>
        </div>
        <div className={style.navSection}>
          <div className={style.linksHeader}>Sponsors</div>
          <ul className={style.linksContainer}>
            <li>
              <a href='https://www.abelgm.com/' target='_blank' rel='noreferrer'>
                Abel Chevrolet
              </a>
            </li>
            <li>
              <a
                href='https://www.americanmuscle.com/chevy-corvette-accessories-parts.html'
                target='_blank'
                rel='noreferrer'
              >
                American Muscle
              </a>
            </li>
            <li>
              <a
                href='https://www.ecklers.com/corvette-parts-accessories.html'
                target='_blank'
                rel='noreferrer'
              >
                Eckler's
              </a>
            </li>
            <li>
              <a href='https://www.idolbeerworks.com/' target='_blank' rel='noreferrer'>
                Idol Beer Works
              </a>
            </li>
            <li>
              <a href='https://www.octaviosrestaurant.com/' target='_blank' rel='noreferrer'>
                Octavio's
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <hr />
      <div>Copyright © 2023 Corvettes of Lodi</div>
      <div>
        <img className={style.logo} src={logo} alt='logo car' />
      </div>
    </footer>
  );
}

export default Footer;
