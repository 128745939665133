import React, { useEffect } from 'react';
import style from '../styles/CorvetteFest.module.scss';
import flyer from '../assets/COL-Corvette-Fest-2024-Flyer.jpg';

function CorvetteFest() {
  useEffect(() => {
    document.title = 'Corvette Fest - Corvettes of Lodi';
  }, []);

  return (
    <div className={style.container}>
      <h1>Corvette Fest 2024 Registration</h1>
      <div>
        <div className={style.registrationInfo}>
          <p>
            Registration for the Corvette Fest Car Show opens <b>June 12th, 2024</b>
          </p>
          <p>
            <a
              className={style.registrationLink}
              href='https://www.motorsportreg.com/events/corvette-fest-car-show-stama-winery-corvettes-of-lodi-258004'
              target='_blank'
              rel='noreferrer'
            >
              CLICK HERE TO REGISTER
            </a>
          </p>
        </div>
        <img className={style.flyer} src={flyer} alt='Corvette Fest flyer' />
      </div>
    </div>
  );
}

export default CorvetteFest;
