import { useEffect } from 'react';

function useHideBodyOverflowY(isHidden) {
  useEffect(() => {
    if (isHidden) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }

    return () => (document.body.style.overflowY = 'scroll');
  }, [isHidden]);
}

export default useHideBodyOverflowY;
