import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Pagination from '../components/Pagination';
import Modal from '../components/Modal';
import Icon from '../components/Icon';
import style from '../styles/Gallery.module.scss';
import headerImg from '../assets/gallery-header.png';

const RML_GALLERY_FOLDER_ID = 3; // The folder id of the Real Media Library (RML) folder used for Gallery content
const ITEMS_PER_PAGE = 36;
function Gallery() {
  let { pageNum } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [media, setMedia] = useState([]);
  const galleryIsLoading = useRef(true);
  const [indexOfSelectedImg, setIndexOfSelectedImg] = useState(null);
  const totalNumPages = useRef();
  const galleryRef = useRef();

  useEffect(() => {
    if (!pageNum || !pageNum.match(/^\d+$/)) {
      navigate('/gallery/page/1', { replace: true });
    }
  }, [pageNum]);

  useEffect(() => {
    if (+pageNum) {
      document.title = `Gallery - Page ${pageNum} - Corvettes of Lodi`;

      (async () => {
        const response = await fetch(
          `https://www.corvettesoflodi.com/wp-json/wp/v2/media?rml_folder=${RML_GALLERY_FOLDER_ID}&page=${pageNum}&per_page=${ITEMS_PER_PAGE}`
        );
        const galleryData = await response.json();
        if (totalNumPages.current === undefined) {
          totalNumPages.current = +response.headers.get('X-WP-TotalPages');
        }

        setMedia(
          galleryData.reduce((imagesAndVideos, galleryItem) => {
            if (
              galleryItem.mime_type.startsWith('image') ||
              galleryItem.mime_type.startsWith('video')
            ) {
              imagesAndVideos.push({
                id: galleryItem.id,
                src: galleryItem.source_url,
                alt: galleryItem.alt_text,
                thumbnail: galleryItem.media_details.sizes.medium_large
                  ? galleryItem.media_details.sizes.medium_large.source_url
                  : '',
                mimeType: galleryItem.mime_type,
              });
            }
            return imagesAndVideos;
          }, [])
        );

        galleryIsLoading.current = false;
      })();
    }
  }, [pageNum]);

  useEffect(() => {
    if (location.state && location.state.from === 'gallery') {
      galleryRef.current.scrollIntoView();
    }
  }, [location]);

  const modalMedia = media[indexOfSelectedImg];

  return (
    <div>
      <div className={style.header}>
        <img className={style.headerImg} src={headerImg} alt='corvette cars' />
        <h1 className={style.headerText}>
          Corvettes of Lodi <span>Gallery</span>
        </h1>
      </div>

      <div ref={galleryRef}>
        {galleryIsLoading.current ? (
          <div className={style.loadingSpinner}>
            <Icon name='loadingSpinner' width='48' height='48' />
          </div>
        ) : (
          <div className={style.galleryItems}>
            {media.map((galleryItem, idx) => {
              return (
                <div
                  className={style.galleryItem}
                  key={galleryItem.id}
                  onClick={() => {
                    setIndexOfSelectedImg(idx);
                  }}
                >
                  {galleryItem.mimeType.startsWith('image') ? (
                    <img
                      src={galleryItem.thumbnail ? galleryItem.thumbnail : galleryItem.src}
                      alt={galleryItem.alt}
                    />
                  ) : (
                    <video src={galleryItem.src} controls />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Pagination
        currPage={+pageNum}
        totalPages={totalNumPages.current}
        onChange={(newPageNum) => {
          navigate(`/gallery/page/${newPageNum}`, {
            state: { from: 'gallery' },
          });
        }}
      />

      <Modal
        isDisplayed={modalMedia !== undefined}
        onClose={() => {
          setIndexOfSelectedImg(null);
        }}
      >
        {indexOfSelectedImg > 0 ? (
          <button
            className={style.viewPrevious}
            onClick={() => {
              setIndexOfSelectedImg(indexOfSelectedImg - 1);
            }}
          >
            <Icon name='chevronLeft' width='48' height='48' />
          </button>
        ) : null}

        {indexOfSelectedImg < media.length - 1 ? (
          <button
            className={style.viewNext}
            onClick={() => {
              setIndexOfSelectedImg(indexOfSelectedImg + 1);
            }}
          >
            <Icon name='chevronRight' width='48' height='48' />
          </button>
        ) : null}

        <div className={style.modalMedia}>
          {modalMedia && modalMedia.mimeType.startsWith('image') ? (
            <img src={modalMedia && modalMedia.src} alt={modalMedia && modalMedia.alt} />
          ) : (
            <video src={modalMedia && modalMedia.src} controls />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Gallery;
