import React from 'react';
import { useEffect, useState } from 'react';
import style from '../styles/ClubOverview.module.scss';
import clubOverviewPicture from '../assets/corvette-club-overview.png';

function ClubOverview() {
  const [screenWidth, setScreenWidth] = useState([window.innerWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth([window.innerWidth]);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function floatParagraphRender() {
    const clubOverviewText = `Corvettes of Lodi started off as a small club back in 1981. After 40+
      years of activity, our club has grown to over 100 members. We are an independent, non-profit
      club and are not affiliated with any regional or national Corvette associations. Part of our
      mission is to cultivate safe driving habits and stimulate the pride of ownership among Corvette
      owners. We're a very active club and average about 12-18 events a year! Events, road trips,
      and social activities are well planned and members are welcome to bring their families along.
      Our members are very friendly and we encourage other Corvette owners to join Corvettes of Lodi.`;

    if (screenWidth > 1349) {
      return (
        <div>
          <p className={style.clubOverviewText}>
            <img
              className={style.clubOverviewPicture}
              src={clubOverviewPicture}
              alt='club members'
            />
            {clubOverviewText}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className={style.clubOverviewText}>{clubOverviewText}</p>
          <img
            className={style.clubOverviewPicture}
            src={clubOverviewPicture}
            alt='club members'
          />
        </div>
      );
    }
  }

  return (
    <section className={style.clubOverviewSection}>
      {floatParagraphRender()}
      <div className={style.contactOverviewMeetingsSection}>
        <div>
          <p>
            Meetings are on the{' '}
            <span className={style.clubOverviewBold}>first Thursday of every month</span> at{' '}
            <span className={style.clubOverviewBold}>Octavio’s</span>
            <span> in the Marina Center:</span>
          </p>
          <div className={style.clubOverviewMeetingInfo}>
            <p>3201 W. Benjamin Holt Drive, Stockton CA, 95219</p>
            <p>Dinner Time: 5:00 PM – 6:00 PM</p>
            <p>Meeting Time: 6:30 PM</p>
            <p>
              Note: Members pay for their own meals and are encouraged to eat at the restaurant.
            </p>
          </div>
        </div>
        <iframe
          className={style.restaurantIFrame}
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.750263016652!2d-121.35903131102633!3d38.0062852264201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80900ea7ec9625b5%3A0xfa18063155e660a3!2sOctavio&#39;s!5e0!3m2!1sen!2sus!4v1704417107241!5m2!1sen!2sus'
          width='200'
          height='200'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
          title="Google Map for Octavio's"
        ></iframe>
      </div>
    </section>
  );
}

export default ClubOverview;
