import React, { useEffect, useState } from 'react';
import style from '../styles/Newsletters.module.scss';
import Icon from '../components/Icon';
import logo from '../assets/logo-car-transparent.png';
import decodeHtml from '../util/decodeHtml';

function Newsletters() {
  const [newsletters2023, setNewsletters2023] = useState([]);
  const [newsletters2024, setNewsletters2024] = useState([]);
  useEffect(() => {
    document.title = 'Newsletters - Corvettes of Lodi';
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        'https://www.corvettesoflodi.com/wp-json/wp/v2/media?rml_folder=6&page=1&order=asc'
      );
      setNewsletters2024(await response.json());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        'https://www.corvettesoflodi.com/wp-json/wp/v2/media?rml_folder=4&page=1&order=asc'
      );
      setNewsletters2023(await response.json());
    })();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.mainContent}>
        <h1>Newsletters</h1>
        <p className={style.about}>
          The COL Newsletters contain the latest information on club activities, like recent
          events, runs, and newly added members, as well as technical information about
          Corvettes. If you missed an event or just want to learn a little more about Corvettes
          of Lodi, you can catch up here! Stay tuned for more to come.
        </p>

        <div className={style.newslettersSection}>
          <div className={style.newslettersListContainer}>
            <ul className={style.newslettersList} data-title='2024 Newsletters'>
              {newsletters2024.map((newsletter) => {
                const title = decodeHtml(newsletter.title.rendered);
                return (
                  <li key={newsletter.id}>
                    <a
                      title={`COL Newsletter ${title} 2024`}
                      href={newsletter.source_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {decodeHtml(newsletter.title.rendered)}
                    </a>

                    <div className={style.iconGroup}>
                      <a
                        href={newsletter.source_url}
                        target='_blank'
                        rel='noreferrer'
                        title='Open newsletter in new tab'
                      >
                        <Icon name='externalLink' width='24' height='24' />
                      </a>
                      <a
                        href={newsletter.source_url.substring(
                          newsletter.source_url.indexOf('/wp-content/uploads')
                        )}
                        title='Download'
                        download
                      >
                        <Icon name='download' width='24' height='24' />
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
            <ul className={style.newslettersList} data-title='2023 Newsletters'>
              {newsletters2023.map((newsletter) => {
                const title = decodeHtml(newsletter.title.rendered);
                return (
                  <li key={newsletter.id}>
                    <a
                      title={`COL Newsletter ${title} 2023`}
                      href={newsletter.source_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {decodeHtml(newsletter.title.rendered)}
                    </a>

                    <div className={style.iconGroup}>
                      <a
                        href={newsletter.source_url}
                        target='_blank'
                        rel='noreferrer'
                        title='Open newsletter in new tab'
                      >
                        <Icon name='externalLink' width='24' height='24' />
                      </a>
                      <a
                        href={newsletter.source_url.substring(
                          newsletter.source_url.indexOf('/wp-content/uploads')
                        )}
                        title='Download'
                        download
                      >
                        <Icon name='download' width='24' height='24' />
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={style.logoContainer}>
            <img src={logo} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletters;
